import { generateParticles } from './particle';
import type { Profile } from './types';

/**
 * Note that angles are measured in radians:
 * radians = (Math.PI / 180) * degrees
 */
const startAngle = 0;
const endAngle = 2 * Math.PI; // 360 degrees in radians
const antiClockwise = true;

export const createDrawFunction = (
  canvasElement: HTMLCanvasElement,
  profile: Profile,
  amount: number,
) => {
  const ctx = canvasElement.getContext('2d');

  if (!ctx) throw new Error('Could not get 2d context for canvas element');

  const { width, height } = canvasElement;

  const particles = generateParticles(profile, amount, {
    width,
    height,
  });

  return () => {
    // Clear the canvas context before updating and animating the particles.
    ctx.clearRect(0, 0, width, height);

    // Updates the particle values before (re) drawing to create an animation on the canvas.
    particles.forEach((particle) => {
      // Update particle values before animating.
      particle.x += particle.deltaX;
      particle.y += particle.deltaY;

      // Update particle opacity based on particle type.
      if (particle.deltaOpacity > 0) {
        if (particle.opacity <= 0) particle.opacity += particle.deltaOpacity;
        if (particle.opacity > 0) particle.opacity -= particle.deltaOpacity;
      }

      // Style the particles.
      ctx.fillStyle = particle.color;
      ctx.globalAlpha = particle.opacity;

      // Animate the particles.
      ctx.beginPath();
      ctx.arc(
        particle.x,
        particle.y,
        particle.radius,
        startAngle,
        endAngle,
        antiClockwise,
      );
      ctx.fill();
      ctx.closePath();

      // Re initialize the particle when it falls out of the view port.
      if (particle.y > height) particle.reset();
    });
  };
};

/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import type { MessageT as ClientMessageT } from '@mentimeter/ragnar-form';
import { Link } from '@mentimeter/ragnar-ui/link';
import {
  AUTHENTICATION_FAILED,
  EMAIL_ALREADY_IN_USE,
  EXPOSED_CREDENTIALS,
  EXPOSED_CREDENTIALS_HELP_ARTICLE_URL,
  FALLBACK_SUSPENDED_USER,
  INVALID_CREDENTIALS,
  IS_SSO_ACCOUNT,
  LOGIN_SERVICE_UNAVAILABLE,
  LOGIN_VERIFICATION_FAILED,
  SIGNUP_FAILED,
  SIGNUP_SERVICE_UNAVAILABLE,
  SOCIAL_ACCOUNT_NO_PASSWORD,
  STRICT_SSO_MESSAGE,
  TOO_MANY_PASSWORD_ATTEMPTS,
} from '../clientErrorMessages';
import {
  ErrorCodes,
  type ClientAuthError,
  type ServerAuthError,
  extractErrorMessage,
  extractMetaData,
} from '../clients/userApi/errors';
import { applyQueryToPath } from './queryString';

const LOGIN_URL = '/auth/login';

export const parseToClientMessage = (
  error: ClientAuthError | ServerAuthError,
  continueUrl: string,
): ClientMessageT | null => {
  switch (error.code) {
    case ErrorCodes.SOCIAL_LOGIN_ENABLED:
      return {
        severity: 'negative',
        children: SOCIAL_ACCOUNT_NO_PASSWORD,
      };
    case ErrorCodes.PASSWORD_ATTEMPT_DENIED:
      return {
        severity: 'negative',
        children: TOO_MANY_PASSWORD_ATTEMPTS,
      };
    case ErrorCodes.NOT_FOUND:
    case ErrorCodes.INVALID_CREDENTIALS:
      return {
        children: INVALID_CREDENTIALS,
        severity: 'negative',
      };
    case ErrorCodes.SUSPENDED_USER: {
      const message = extractErrorMessage(error);
      return {
        children: message || FALLBACK_SUSPENDED_USER,
        severity: 'negative',
      };
    }
    case ErrorCodes.SERVICE_UNAVAILABLE: {
      if (error.type === 'signup') {
        return {
          children: SIGNUP_SERVICE_UNAVAILABLE,
          severity: 'negative',
        };
      }
      return {
        children: LOGIN_SERVICE_UNAVAILABLE,
        severity: 'negative',
      };
    }
    case ErrorCodes.EMAIL_NOT_UNIQUE:
      const loginHref = applyQueryToPath(LOGIN_URL);
      return {
        children: (
          <>
            {EMAIL_ALREADY_IN_USE} Do you want to{' '}
            <Link href={loginHref}>log in?</Link>
          </>
        ),
        severity: 'negative',
      };

    case ErrorCodes.EXPOSED_CREDENTIALS:
      return {
        children: (
          <>
            {EXPOSED_CREDENTIALS}{' '}
            <Link
              href={EXPOSED_CREDENTIALS_HELP_ARTICLE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Link>
          </>
        ),
        severity: 'negative',
      };

    case ErrorCodes.RESTRICTED_DOMAIN: {
      const metaData = extractMetaData(error);
      let loginUrl = metaData?.loginUrl;
      if (continueUrl) {
        loginUrl += `?continue=${continueUrl}`;
      }

      return {
        children: (
          <>
            {STRICT_SSO_MESSAGE} Please signup{' '}
            <Link href={loginUrl}>here.</Link>
          </>
        ),
        severity: 'info',
      };
    }

    case ErrorCodes.SAML_LOGIN_ENABLED: {
      const metaData = extractMetaData(error);
      let loginUrl = metaData?.loginUrl;
      if (continueUrl) {
        loginUrl += `?continue=${continueUrl}`;
      }

      return {
        children: (
          <>
            {IS_SSO_ACCOUNT} Do you want to <Link href={loginUrl}>log in</Link>?
          </>
        ),
        severity: 'info',
      };
    }
  }

  if (error.type === 'login') {
    return {
      children: LOGIN_VERIFICATION_FAILED,
      severity: 'negative',
    };
  }

  if (error.type === 'signup') {
    return {
      children: SIGNUP_FAILED,
      severity: 'negative',
    };
  }

  if (error.type === 'social') {
    return null;
  }

  return {
    children: AUTHENTICATION_FAILED,
    severity: 'negative',
  };
};

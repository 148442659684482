import { useEffect, useState } from 'react';

export const useClientSearchParam = (
  param: string,
): [string | undefined, boolean] => {
  // Use URL api to avoid server cached version of query
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setQueryValue(
      new URL(window.location.href).searchParams.get(param) || undefined,
    );
    setIsLoading(false);
  }, [param]);

  return [queryValue, isLoading];
};

import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Heading, P } from '@mentimeter/ragnar-ui/typography';
import { Link } from '@mentimeter/ragnar-ui/link';

interface CompanyContactInfoT {
  companyContactEmail?: string | undefined;
  companyContactUrl?: string | undefined;
  companyContactName?: string | undefined;
  companyName: string;
  loginUrl: string;
}
interface PropsT extends CompanyContactInfoT {
  resetForm: () => void;
}

const renderCompanyLink = (companyContactInfo: CompanyContactInfoT) => {
  const { companyContactEmail, companyContactUrl, companyContactName } =
    companyContactInfo;
  if (companyContactEmail) {
    return (
      <Link href={`mailto:${companyContactEmail}`}>{companyContactEmail}</Link>
    );
  }
  if (companyContactUrl) {
    return (
      <Link href={companyContactUrl} target="blank" rel="noopener noreferrer">
        {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
        {companyContactName}
      </Link>
    );
  }
  return;
};

const renderRequestLicenseFromCompanyMessage = (
  companyContactInfo: CompanyContactInfoT,
) => {
  const { companyName } = companyContactInfo;
  return (
    <P fontSize={[2, 3]} mb={[2, 4]} textAlign="center">
      To continue, you first need to get approval from {companyName}. Please
      request a license from {renderCompanyLink(companyContactInfo)}.
    </P>
  );
};

const renderBodyMessage = (companyContactInfo: CompanyContactInfoT) => {
  const { loginUrl, companyContactEmail, companyContactUrl } =
    companyContactInfo;
  return companyContactEmail || companyContactUrl ? (
    <>{renderRequestLicenseFromCompanyMessage(companyContactInfo)}</>
  ) : (
    <Button href={loginUrl} mb={4} variant="primary">
      Sign up with SSO
    </Button>
  );
};

const StrictSSOInfo = ({
  resetForm,
  companyName,
  loginUrl,
  companyContactEmail,
  companyContactUrl,
  companyContactName,
}: PropsT) => {
  const companyContactInfo: CompanyContactInfoT = {
    loginUrl,
    companyContactEmail,
    companyContactName,
    companyContactUrl,
    companyName,
  };
  return (
    <Box width="100%" alignItems="stretch">
      <Heading textAlign="center" fontSize={4} color="inherit">
        {companyName} has an agreement with Mentimeter
      </Heading>
      <P as="p" fontSize={[2, 3]} mb={[2, 4]} textAlign="center">
        You need to sign up with SSO to join your colleagues and start using
        Mentimeter.
      </P>
      {renderBodyMessage(companyContactInfo)}
      <Box
        p={3}
        bg="neutralWeakest"
        borderRadius={2}
        alignItems="center"
        justifyContent="center"
      >
        <P textAlign="center" mb={0}>
          Alternatively, you can use another email address to{' '}
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              resetForm();
            }}
          >
            sign up for a personal account
          </Link>
          .
        </P>
      </Box>
    </Box>
  );
};

export default StrictSSOInfo;

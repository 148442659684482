import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { ClaimInvite } from './ClaimInviteToken';
import type { JoinErrorEnum } from './handleErrorMessage';
import { RestrictedDomainsForm } from './RestrictedDomainsForm';
import { AnyEmailForm } from './AnyEmailForm';

export function ClaimInviteForm({
  emailDomains,
  teamUrl,
  teamName,
  joinLinkRestrictionEnabled,
  setClaimInvite,
  onClaimInviteError,
}: {
  emailDomains: string | undefined | null;
  teamUrl: string;
  teamName: string;
  joinLinkRestrictionEnabled: boolean | null;
  setClaimInvite: (value: React.SetStateAction<ClaimInvite>) => void;
  onClaimInviteError: (state: JoinErrorEnum) => void;
}): React.ReactElement {
  return (
    <Box maxWidth="500px">
      <Box mb="space8" width="100%">
        <Heading mb="space6" fontSize={['175', '225']}>
          Join {teamName}&apos;s team
        </Heading>
        <Text fontSize="112.5">
          Enter your email address to join {teamName}&apos;s team
        </Text>
      </Box>
      {joinLinkRestrictionEnabled ? (
        <RestrictedDomainsForm
          emailDomains={emailDomains}
          teamUrl={teamUrl}
          teamName={teamName}
          onClaimInviteSuccess={({ status, email }) => {
            setClaimInvite((state: ClaimInvite) => ({
              ...state,
              claims: state.claims + 1,
              status,
              email,
            }));
          }}
          onClaimInviteError={onClaimInviteError}
        />
      ) : (
        <AnyEmailForm
          teamUrl={teamUrl}
          teamName={teamName}
          onClaimInviteSuccess={({ status, email }) => {
            setClaimInvite((state: ClaimInvite) => ({
              ...state,
              claims: state.claims + 1,
              status,
              email,
            }));
          }}
          onClaimInviteError={onClaimInviteError}
        />
      )}
    </Box>
  );
}

import md5 from 'crypto-js/md5';
import type { Regions } from './core/types';
import { core } from './global-http';

const workspaceRegionCache = new Map<string, Regions>();

export async function getWorkspaceRegion(nameOrURL: string) {
  const hashed = md5(nameOrURL.toLowerCase()).toString();
  const cachedValue = workspaceRegionCache.get(hashed);
  if (cachedValue) return cachedValue;

  const res = await core({ region: 'eu' }).region.workspaces(hashed);
  workspaceRegionCache.set(hashed, res.data.region);

  return res.data.region;
}

import * as React from 'react';
import { TextInput, type TextInputProps } from '@mentimeter/ragnar-form';
import { EyeIcon, EyeOffIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';

export const PasswordInput: React.FC<Omit<TextInputProps, 'type'>> = (
  props,
) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => {
    setShowPassword((showPass) => !showPass);
  };

  return (
    <Box position="relative">
      <TextInput
        type={showPassword ? 'text' : 'password'}
        width="100%"
        extend={({ theme }) => ({
          '>*>input': {
            paddingRight: `${theme.space[4]}px`,
            '&:focus': {
              paddingRight: `${theme.space[4]}px`,
            },
          },
        })}
        {...props}
      />
      <Clickable
        onClick={toggleShowPassword}
        position="absolute"
        type="button"
        aria-label={showPassword ? 'Hide password' : 'Show password'}
        extend={({ theme }) => {
          return {
            top: `${theme.kosmosSpacing.space8}px`,
            right: `${theme.space[2]}px`,
            transform: 'translateY(20%)',
          };
        }}
      >
        {showPassword ? <EyeOffIcon size={3} /> : <EyeIcon size={3} />}
      </Clickable>
    </Box>
  );
};

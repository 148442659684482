'use client';

import React from 'react';
import { useUser } from '@mentimeter/user';
import type { TrackingContext } from '@mentimeter/http-clients';
import { useSearchParams } from '@mentimeter/next-navigation';
import { useSubscriptions } from '@mentimeter/billing';
import { trackDropOutReason } from '../join-page/utils';
import { JoinErrorEnum, parseErrorCode } from '../join-page/handleErrorMessage';
import { JoinTeamModalContainer } from './JoinTeamModalContainer';
import type { AuthStateT, JoinModeT } from './utils';
import { useTeamDetails } from './use-team-details';

export function JoinTeamModal({
  inviteSeriesId,
  trackingContext,
}: {
  inviteSeriesId?: string;
  trackingContext: TrackingContext;
}) {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const searchParams = useSearchParams();
  const continuePath = searchParams.get('continuePath');
  const token = searchParams.get('token');
  const authState = searchParams.get('authState') as AuthStateT | null;
  const joinMode = searchParams.get('joinMode') as JoinModeT | null;

  const {
    teamDetails,
    isFetchingTeamDetails,
    error: teamDetailsError,
  } = useTeamDetails(token, {
    onError: () => {
      const errorMessage = parseErrorCode({
        teamName: teamDetails.name,
        error: JoinErrorEnum.WORKSPACE_INVITE_NOT_FOUND,
      });
      if (user) {
        trackDropOutReason({
          reason: errorMessage.trackingDropoutReason,
          userId: user.id,
          teamName: teamDetails.name,
          joinMode,
          authState,
          path: window.location.pathname,
        });
      }
    },
  });

  if (!user || !token) {
    return null;
  }

  if (!authState || !joinMode || !teamDetails || isFetchingTeamDetails)
    return null;

  return (
    <JoinTeamModalContainer
      token={token}
      authState={authState}
      joinMode={joinMode}
      inviteSeriesId={inviteSeriesId}
      user={user}
      subscriptions={subscriptions}
      trackingContext={trackingContext}
      teamDetailsError={teamDetailsError}
      teamDetails={teamDetails}
      {...(continuePath && { continuePath })}
    />
  );
}

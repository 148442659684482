import { useUser } from '@mentimeter/user';
import * as Yup from 'yup';
import { Form, TextInput } from '@mentimeter/ragnar-form';
import { useButtonState } from '@mentimeter/ragnar-ui/button';
import { parseErrorCode, type JoinErrorEnum } from './handleErrorMessage';
import { VerifyEmailButton } from './VerifyEmailButton';
import { handleSubmit } from './utils';

const anyEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      'The email does not have the correct format of brienne@tarth.com. Please adjust and try again.',
    )
    .required('Please fill in your email address')
    .max(150, 'Aren’t you a bit long for an email address?'),
});

export const AnyEmailForm = ({
  teamUrl,
  teamName,
  onClaimInviteSuccess,
  onClaimInviteError,
}: {
  teamUrl: string;
  teamName: string;
  onClaimInviteSuccess: ({
    status,
    email,
  }: { status: number; email: string }) => void;
  onClaimInviteError: (error: JoinErrorEnum) => void;
}) => {
  const { user } = useUser();
  const [buttonState, setButtonState] = useButtonState();

  return (
    <Form
      initialValues={{
        email: user ? user.email : '',
      }}
      validationSchema={anyEmailValidationSchema}
      onSubmit={async (data, formikActions) => {
        try {
          const email = data.email.trim();
          const { status } = await handleSubmit({
            email,
            url: teamUrl,
          });
          setButtonState('success');
          onClaimInviteSuccess({ status, email });
        } catch (e: any) {
          const error = e.response?.data?.code || 'default';
          const errorMessage = parseErrorCode({ error, teamName });

          onClaimInviteError(error);
          formikActions.setSubmitting(false);
          formikActions.setErrors({ email: errorMessage.formError });
          setButtonState('error');
        }
      }}
      width={1}
      alignItems="center"
    >
      <TextInput
        name="email"
        type="email"
        label="Email address"
        placeholder="brienne@tarth.com"
        width="100%"
      />
      <VerifyEmailButton buttonState={buttonState} />
    </Form>
  );
};

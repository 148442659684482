import React, { type ReactNode, type JSX } from 'react';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Image } from '@mentimeter/ragnar-ui/image';
import { ModalHeader } from '@mentimeter/ragnar-ui/modal';

const MODAL_HEIGHT = '420px';

export const ModalContainer = ({
  image,
  children,
}: {
  image: string;
  children: ReactNode;
}): JSX.Element => {
  const isMobileDevice = useMatch({ lessThan: 3 });

  if (isMobileDevice) {
    return (
      <Box
        width={['100%', '100%', '315px']}
        justifyContent="center"
        alignItems="center"
        bg="white"
        mt={4}
      >
        <Box
          minHeight="100%"
          justifyContent="space-between"
          flexDirection="column"
          width={['100%', '100%', null]}
          flex="1 1 auto"
          p={[4, 4, 3]}
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box flexDirection="row" flex="1 1 auto" alignItems="stretch">
      <Box
        width={['100%', '100%', '315px']}
        minHeight={MODAL_HEIGHT}
        justifyContent="center"
        alignItems="center"
        bg="borderNoticeWeakest"
        p={[4, 4, 3]}
      >
        <Image src={image} alt="image of hand" />
      </Box>
      <Box flex="1 1 auto">
        <ModalHeader p={0} />
        <Box flex="1 1 auto" px={4} py={4} justifyContent="space-between">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

import type { UserT } from '@mentimeter/user';
import {
  PlanKeyEnum,
  WorkspaceRoleEnum,
  type SubscriptionPlanFeaturesT,
  type SubscriptionsResponseT,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { JoinErrorEnum } from '../join-page/handleErrorMessage';

export type JoinModeT = 'join' | 'invite';
export type AuthStateT = 'login' | 'signup';

export interface TeamDetailsT {
  name: string;
  planFeatures: SubscriptionPlanFeaturesT;
  members_count: number;
  invited_role: WorkspaceRole;
}

interface AllowedToJoinT {
  valid: true;
}

interface AllowedToJoinInvalidT {
  valid: false;
  code: JoinErrorEnum;
}

export type AllowedToJoinStates = AllowedToJoinT | AllowedToJoinInvalidT;

export const isAllowedToJoin = (
  user: UserT,
  subscription: SubscriptionsResponseT | undefined,
): AllowedToJoinT | AllowedToJoinInvalidT => {
  const isMemberOfTeam =
    (subscription?.current_subscription?.licenses ?? 0) > 1;
  const isEnterpriseUser =
    user.features.type === 'enterprise' ||
    subscription?.current_subscription?.plan.key ===
      PlanKeyEnum.ENTERPRISE_TRIAL;

  if (user.role === WorkspaceRoleEnum.OWNER && isMemberOfTeam) {
    return { valid: false, code: JoinErrorEnum.USER_IS_TEAM_OWNER };
  }
  if (isEnterpriseUser) {
    return { valid: false, code: JoinErrorEnum.USER_IS_ENTERPRISE_MEMBER };
  }
  if (isMemberOfTeam) {
    return { valid: false, code: JoinErrorEnum.USER_IS_TEAM_MEMBER };
  }
  return { valid: true };
};

export enum PlansName {
  registered = 'Free',
  basic = 'Basic',
  pro = 'Pro',
  enterprise = 'Enterprise',
  enterprise_trial = 'Enterprise',
}

import type { UserT } from '@mentimeter/user';
import { JoinErrorEnum } from '../join-page/handleErrorMessage';
import { JoinTeamErrorModalContent } from './JoinTeamErrorModalContent';
import { JoinTeamSuccessModalContent } from './JoinTeamSuccessModalContent';
import { JoinTeamConsent } from './require-action-from-user/JoinTeamConsent';
import type { ConsumeStatus } from './types';
import type { TeamDetailsT } from './utils';

export const JoinTeamModalContent = ({
  teamDetails,
  user,
  consumeStatus,
  onSuccessActionClick,
  successActionText,
  onJoinWorkspaceClick,
  teamName,
  onDismiss,
  isLoading,
}: {
  teamDetails: TeamDetailsT;
  user: UserT;
  consumeStatus: ConsumeStatus;
  successActionText: string;
  onSuccessActionClick: () => void;
  onJoinWorkspaceClick: () => void;
  teamName: string;
  onDismiss: () => void;
  isLoading: boolean;
}) => {
  const blockPaidUserJoinFreeWorkspace =
    !user.isFreeUser && teamDetails.planFeatures === 'registered';

  if (consumeStatus.state === 'success') {
    return (
      <JoinTeamSuccessModalContent
        teamName={teamName}
        onActionClick={onSuccessActionClick}
        actionText={successActionText}
        role={teamDetails.invited_role}
      />
    );
  }

  if (consumeStatus.state === 'pending-consent') {
    if (blockPaidUserJoinFreeWorkspace) {
      return (
        <JoinTeamErrorModalContent
          error={JoinErrorEnum.USER_HAS_OTHER_SUBSCRIPTION}
          teamName={teamName}
        />
      );
    }

    return (
      <JoinTeamConsent
        onJoinWorkspaceClick={onJoinWorkspaceClick}
        user={user}
        isLoading={isLoading}
        onDismiss={onDismiss}
        newPlan={teamDetails.planFeatures}
        teamName={teamName}
        invitedRole={teamDetails.invited_role}
      />
    );
  }

  return (
    <JoinTeamErrorModalContent
      error={consumeStatus.error}
      teamName={teamName}
    />
  );
};

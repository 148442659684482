import { Form, TextInput } from '@mentimeter/ragnar-form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';

export const ConfirmWithConsent = ({
  subscription,
  onDismiss,
  onJoinWorkspaceClick,
  isLoading,
}: {
  subscription: string;
  onDismiss: () => void;
  onJoinWorkspaceClick: () => void;
  isLoading: boolean;
}) => {
  const validationScheme = Yup.object().shape({
    'input-consent': Yup.string()
      .required('Make sure you typed CANCEL')
      .oneOf(['CANCEL'], 'Make sure you typed CANCEL'),
  });
  const form = useFormik({
    initialValues: { 'input-consent': '' },
    enableReinitialize: true,
    validationSchema: validationScheme,
    onSubmit: onJoinWorkspaceClick,
  });

  return (
    <>
      <Box flexDirection="column" width="100%">
        <Text color="textNegative" mb={1}>
          Cancel your {subscription} subscription?
        </Text>
        <Text>
          Type CANCEL to confirm you&apos;ve read and agreed to the information
          above. This action cannot be reversed.
        </Text>
      </Box>
      <Form
        validationSchema={validationScheme}
        initialValues={form.initialValues}
        onSubmit={form.handleSubmit}
        width="100%"
      >
        <>
          <TextInput
            label=""
            name="input-consent"
            id="input-consent"
            data-testid="input-field-consent-cancel-subscription"
            onChange={form.handleChange}
            width={['100%', '80%']}
            mt={1}
          />
          <Box
            flexDirection={['column', 'row']}
            width="100%"
            justifyContent="flex-end"
            mt={2}
          >
            <Button
              variant="secondary"
              mr={1}
              onClick={onDismiss}
              width={['100%', 'auto', 'auto']}
            >
              No, keep my subscription
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                !form.isValid ||
                form.values['input-consent'].length < 1 ||
                isLoading
              }
              width={['100%', 'auto', 'auto']}
              mt={[2, 0]}
            >
              Join workspace
            </Button>
          </Box>
        </>
      </Form>
    </>
  );
};

import React from 'react';
import { Confetti } from '@mentimeter/confetti';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import {
  WorkspaceRoleEnum,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Heading, P, Strong } from '@mentimeter/ragnar-ui/typography';
import { ModalContainer } from './ModalContainer';
import hands1 from './images/hands-2.svg';

export const JoinTeamSuccessModalContent = ({
  teamName,
  onActionClick,
  actionText,
  role,
}: {
  teamName: string;
  onActionClick: () => void;
  actionText: string;
  role: WorkspaceRole;
}) => (
  <>
    <Confetti colors={[...designSystemConfig.visualizationColors.fillColors]} />
    <ModalContainer image={hands1.src}>
      <Box
        flexDirection="column"
        justifyContent="space-between"
        overflow="auto"
        flex="1 1 auto"
        width="100%"
      >
        <Box width="100%">
          <Heading mb={2}>Welcome to {teamName}&apos;s workspace!</Heading>
          {role === WorkspaceRoleEnum.MEMBER_LITE ? (
            <P mt={3} mb={2}>
              Congratulations, you successfully joined the{' '}
              <Strong>{teamName}</Strong> workspace as a free member. You can
              now share and edit presentations with your teammates.
            </P>
          ) : (
            <P mt={3} mb={2}>
              Congratulations, you are now part of <Strong>{teamName}</Strong>
              &apos;s workspace!
            </P>
          )}
        </Box>
      </Box>
      <Box flexDirection="row" width="100%" justifyContent="flex-end">
        <Button variant="primary" onClick={onActionClick}>
          {actionText}
        </Button>
      </Box>
    </ModalContainer>
  </>
);

import React from 'react';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';

export const VerifyEmailButton = ({
  buttonState,
}: {
  buttonState: RagnarButtonState | undefined;
}) => {
  return (
    <Button
      state={buttonState}
      aria-label="Verify email"
      width="100%"
      variant="primary"
      type="submit"
      mx={0}
      mt="space2"
    >
      Verify email
    </Button>
  );
};

import React, { useEffect, useRef } from 'react';
import { createDrawFunction } from './createDrawFunction';
import type { ParticleType, Velocity } from './types';

interface Props {
  colors: string[];
  width?: number;
  height?: number;
  amount?: number;
  type?: ParticleType;
  velocity?: Velocity;
}

const isBrowser = typeof window !== 'undefined';

export function Confetti({
  colors: colorPalette,
  width,
  height,
  amount = 500,
  type = 'confetti',
  velocity = 'fast',
}: Props) {
  const canvasElement = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasElement.current) return;

    const profile = { type, velocity, colorPalette };
    const draw = createDrawFunction(canvasElement.current, profile, amount);

    let id = 0;
    function runAnimation() {
      id = requestAnimationFrame(() => {
        draw();
        runAnimation();
      });
    }

    runAnimation();

    return () => {
      if (id !== 0) cancelAnimationFrame(id);
    };
  }, [amount, colorPalette, type, velocity]);

  return (
    <canvas
      id="react-snowfetti"
      ref={canvasElement}
      width={(width ?? isBrowser) ? window.innerWidth : 0}
      height={(height ?? isBrowser) ? window.innerHeight : 0}
      style={{
        pointerEvents: 'none',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}
    >
      <h3>Oh no! You do not have support for the html5 canvas API!</h3>
    </canvas>
  );
}

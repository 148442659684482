import * as React from 'react';
import { useTranslations } from '@mentimeter/i18n';
import { Meta } from '@mentimeter/ragnar-ui/typography';
import { Box } from '@mentimeter/ragnar-ui/box';

export const Divider = () => {
  const t = useTranslations('authPackage');

  return (
    <Box my={3} alignItems="center">
      <Meta as="p" textAlign="center">
        {t('or_email')}
      </Meta>
    </Box>
  );
};

export enum JoinErrorEnum {
  INACTIVE_WORKSPACE = 'inactive_workspace',
  TOO_MANY_MEMBERS = 'too_many_members',
  INACTIVE_JOIN_PAGE = 'inactive_join_page',
  WORKSPACE_INVITE_NOT_FOUND = 'workspace_pending_membership_not_found',

  WRONG_EMAIL_INVITED = 'user_email_invited',
  USER_HAS_OTHER_SUBSCRIPTION = 'user_has_other_subscription',
  USER_IS_TEAM_OWNER = 'user_is_team_owner',
  USER_IS_TEAM_MEMBER = 'user_is_team_member',
  USER_IS_ENTERPRISE_MEMBER = 'user_is_enterprise_member',
  USER_HAS_SSO = 'saml_subscription_member',

  WORKSPACE_NOT_FOUND = 'workspace_not_found',
  DEFAULT = 'default_error_message',
}

export enum ErrorImage {
  MOON = 'moon',
  MAGNIFYING = 'magnifying',
  LETTER = 'letter',
  HANDSHAKE = 'handshake',
  PUZZLE = 'puzzle',
  CAT = 'cat',
}

export interface ErrorMessageT {
  title: string;
  main: string;
  image: ErrorImage;
  ctaButton: { support: boolean; manageMembers?: boolean };
  code: JoinErrorEnum;
  trackingDropoutReason: string;
  isMembershipError?: boolean;
  formError?: string;
}

interface ErrorT {
  error: JoinErrorEnum;
  teamName: string;
}

export const parseErrorCode = ({ error, teamName }: ErrorT): ErrorMessageT => {
  const errorMessages: Record<JoinErrorEnum, ErrorMessageT> = {
    [JoinErrorEnum.INACTIVE_JOIN_PAGE]: {
      title: `This workspace is currently closed`,
      main: `The workspace administrator has closed down the possibility to join. Reach out to them to ask them to open up or send a new invitation.`,
      image: ErrorImage.MOON,
      ctaButton: { support: true },
      code: JoinErrorEnum.INACTIVE_JOIN_PAGE,
      trackingDropoutReason: 'join page inactive',
    },
    [JoinErrorEnum.INACTIVE_WORKSPACE]: {
      title: `Workspace not active`,
      main: `Check back later. If you believe this is a mistake, contact support.`,
      image: ErrorImage.MOON,
      ctaButton: { support: true },
      code: JoinErrorEnum.INACTIVE_WORKSPACE,
      trackingDropoutReason: 'team is not active',
    },
    [JoinErrorEnum.TOO_MANY_MEMBERS]: {
      title: `All licenses have been taken`,
      main: `${teamName} workspace doesn't have any licenses available. Please contact your administrator for help, or ask them to invite you as a Member Lite.`,
      image: ErrorImage.CAT,
      ctaButton: { support: true },
      code: JoinErrorEnum.TOO_MANY_MEMBERS,
      trackingDropoutReason: 'team is full',
    },
    [JoinErrorEnum.WORKSPACE_INVITE_NOT_FOUND]: {
      title: `This invitation doesn't work`,
      main: `This invitation has already been used or isn't active anymore. Ask your administrator for another invitation.`,
      image: ErrorImage.LETTER,
      ctaButton: { support: true },
      code: JoinErrorEnum.WORKSPACE_INVITE_NOT_FOUND,
      trackingDropoutReason: 'invalid invitation',
    },
    [JoinErrorEnum.USER_IS_TEAM_OWNER]: {
      title: `We can't let you go`,
      main: `You can't join this workspace because you own another workspace. To join the new workspace, you need to remove all your current and pending teammates.`,
      image: ErrorImage.HANDSHAKE,
      ctaButton: { support: false, manageMembers: true },
      code: JoinErrorEnum.USER_IS_TEAM_OWNER,
      trackingDropoutReason: 'user is team owner',
      isMembershipError: true,
      formError: `You can't join this workspace because you own another workspace. To join the new workspace, you need to remove all your current and pending teammates.`,
    },
    [JoinErrorEnum.USER_IS_TEAM_MEMBER]: {
      title: `Can't join workspace`,
      main: `You can't join this workspace because you're in another workspace. Reach out to support for help.`,
      image: ErrorImage.HANDSHAKE,
      ctaButton: { support: true },
      code: JoinErrorEnum.USER_IS_TEAM_MEMBER,
      trackingDropoutReason: 'user is team member',
      isMembershipError: true,
      formError: `You can't join this workspace because you're in another workspace. Reach out to support for help.`,
    },
    [JoinErrorEnum.USER_IS_ENTERPRISE_MEMBER]: {
      title: `Can't join workspace`,
      main: `You can't join this workspace because you're in another workspace. Reach out to support for help.`,
      image: ErrorImage.HANDSHAKE,
      ctaButton: { support: true },
      code: JoinErrorEnum.USER_IS_ENTERPRISE_MEMBER,
      trackingDropoutReason: 'user is enterprise member',
      isMembershipError: true,
      formError: `You can't join this workspace because you're in another workspace. Reach out to support for help.`,
    },
    [JoinErrorEnum.USER_HAS_OTHER_SUBSCRIPTION]: {
      title: `Can't join workspace`,
      main: `You can't join this workspace because you already have a subscription. Reach out to support for help.`,
      image: ErrorImage.HANDSHAKE,
      ctaButton: { support: true },
      code: JoinErrorEnum.USER_HAS_OTHER_SUBSCRIPTION,
      trackingDropoutReason: 'user is on a paid subscription',
      isMembershipError: true,
      formError: `You can't join this workspace because you already have a subscription. Reach out to support for help.`,
    },
    [JoinErrorEnum.USER_HAS_SSO]: {
      title: `Can't join workspace`,
      main: `You can't join this workspace because you're in another workspace. Reach out to support for help.`,
      image: ErrorImage.HANDSHAKE,
      ctaButton: { support: true },
      code: JoinErrorEnum.USER_HAS_SSO,
      trackingDropoutReason: 'user is on SSO workspace',
    },
    [JoinErrorEnum.WORKSPACE_NOT_FOUND]: {
      title: 'Workspace not found',
      main: `If you believe this is a mistake, contact support.`,
      image: ErrorImage.MAGNIFYING,
      ctaButton: { support: true },
      code: JoinErrorEnum.WORKSPACE_NOT_FOUND,
      trackingDropoutReason: 'team not found',
    },
    [JoinErrorEnum.WRONG_EMAIL_INVITED]: {
      title: `Can't join workspace`,
      main: `You can't join this workspace because the email does not match the invitation`,
      image: ErrorImage.LETTER,
      ctaButton: { support: true },
      code: JoinErrorEnum.WRONG_EMAIL_INVITED,
      trackingDropoutReason: 'user email does not match invitation',
    },
    [JoinErrorEnum.DEFAULT]: {
      title: `Can't join workspace`,
      main: `If you believe this is a mistake, contact support.`,
      image: ErrorImage.MAGNIFYING,
      ctaButton: { support: true },
      code: JoinErrorEnum.DEFAULT,
      trackingDropoutReason: error || 'unknown error',
    },
  };

  return errorMessages[error] ?? errorMessages[JoinErrorEnum.DEFAULT];
};

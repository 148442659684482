import {
  WorkspaceRoleEnum,
  type SubscriptionPlanFeaturesT,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import type { UserT } from '@mentimeter/user';
import { ContactSupport, JoinTeamPaidToMemberLite } from '../_utils';
import { JoinTeamConsentPaidUserContent } from '../JoinTeamConsentPaidUserContent';
import { PlansName } from '../utils';
import { ConfirmWithConsent } from './ConfirmWithConsent';

export const PaidUser = ({
  invitedRole,
  user,
  newPlan,
  isLoading,
  onDismiss,
  onJoinWorkspaceClick,
}: {
  invitedRole: WorkspaceRole;
  user: Pick<UserT, 'isFreeUser' | 'email'> & {
    features: { type: UserT['features']['type'] };
  };
  newPlan: SubscriptionPlanFeaturesT;
  isLoading: boolean;
  onDismiss: () => void;
  onJoinWorkspaceClick: () => void;
}) => {
  const content =
    invitedRole === WorkspaceRoleEnum.MEMBER_LITE ? (
      <JoinTeamPaidToMemberLite
        email={user.email}
        currentPlan={user.features.type}
      />
    ) : (
      <JoinTeamConsentPaidUserContent
        email={user.email}
        currentPlan={user.features.type}
        newPlan={newPlan}
      />
    );

  return (
    <>
      {content}
      <ContactSupport />
      <ConfirmWithConsent
        subscription={PlansName[user.features.type]}
        onDismiss={onDismiss}
        onJoinWorkspaceClick={onJoinWorkspaceClick}
        isLoading={isLoading}
      />
    </>
  );
};

import { Link } from '@mentimeter/ragnar-ui/link';
import { Meta } from '@mentimeter/ragnar-ui/typography';

export function TermsOfUse() {
  return (
    <Meta mt={3}>
      By verifying your email you accept our{' '}
      <Link href="/terms" target="_blank" rel="noopener noreferrer">
        terms of use
      </Link>
    </Meta>
  );
}

import { useUser } from '@mentimeter/user';
import { Form, Select, TextInput } from '@mentimeter/ragnar-form';
import * as Yup from 'yup';
import { Box } from '@mentimeter/ragnar-ui/box';
import { useButtonState } from '@mentimeter/ragnar-ui/button';
import { parseErrorCode } from './handleErrorMessage';
import type { JoinErrorEnum } from './handleErrorMessage';
import { handleSubmit } from './utils';
import { VerifyEmailButton } from './VerifyEmailButton';

const splitEmailAddress = ({ userEmail }: { userEmail: string }) => {
  const splitEmail = userEmail.split('@');
  const userName = splitEmail[0];
  const userDomain = splitEmail[1];

  return { userName, userDomain };
};

const restrictedDomainsValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please fill in your email address without domain')
    .max(100, 'Aren’t you a bit long for an email address without domain?')
    .matches(/^((?!@).)*$/, 'Please enter the email address without domain'),
  domain: Yup.string().required('Please select a domain'),
});

export const RestrictedDomainsForm = ({
  emailDomains,
  teamUrl,
  teamName,
  onClaimInviteSuccess,
  onClaimInviteError,
}: {
  emailDomains: string | undefined | null;
  teamUrl: string;
  teamName: string;
  onClaimInviteSuccess: ({
    status,
    email,
  }: { status: number; email: string }) => void;
  onClaimInviteError: (error: JoinErrorEnum) => void;
}) => {
  const { user } = useUser();
  const [buttonState, setButtonState] = useButtonState();
  const emailDomainsArray = emailDomains ? emailDomains?.split(', ') : [];

  const { userName, userDomain } = splitEmailAddress({
    userEmail: user ? user.email : '',
  });

  return (
    <Form
      initialValues={{
        email: userName,
        domain: userDomain || emailDomainsArray[0],
      }}
      validationSchema={restrictedDomainsValidationSchema}
      onSubmit={async (data, formikActions) => {
        setButtonState('loading');

        try {
          const email = `${data.email.trim()}@${data.domain}`;
          const { status } = await handleSubmit({
            email,
            url: teamUrl,
          });

          onClaimInviteSuccess({ status, email });

          setButtonState('success');
        } catch (e: any) {
          const error = e.response?.data?.code || 'default';
          const errorMessage = parseErrorCode({ error, teamName });

          onClaimInviteError(error);
          formikActions.setSubmitting(false);
          formikActions.setErrors({ email: errorMessage.formError });
          setButtonState('error');
        }
      }}
      width={1}
      alignItems="center"
    >
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="normal"
        width="100%"
      >
        <TextInput
          aria-label="Email input without domain"
          m={0}
          flex="1 1 auto"
          name="email"
          label="Email address"
          placeholder="brienne.tarth"
        />
        <Select
          aria-label="Company domain"
          ml={1}
          width={[null, '50%']}
          label="&nbsp;"
          name="domain"
          key="domain"
          disabled={emailDomainsArray.length === 1}
          options={emailDomainsArray.map((domain) => ({
            key: domain,
            value: domain,
            label: `@${domain}`,
          }))}
        />
      </Box>
      <VerifyEmailButton buttonState={buttonState} />
    </Form>
  );
};

'use client';

import React, { useEffect } from 'react';
import { AuthPackage, AuthSwitch, useReferral } from '@mentimeter/auth';
import { usePathname, useSearchParams } from '@mentimeter/next-navigation';
import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { useUser } from '@mentimeter/user';
import { useTranslations } from '@mentimeter/i18n';
import { trackViewedPage } from '@mentimeter/dashboard-tracking';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import { useClientSearchParam } from '../../utils/useClientSearchParams';
import type { JoinErrorEnum } from './handleErrorMessage';
import { parseErrorCode } from './handleErrorMessage';
import { JoinError } from './JoinError';
import { trackDropOutReason } from './utils';

export interface ConfirmAccountPropsT {
  teamName: string;
  email: string | null;
  error?: JoinErrorEnum | undefined | null;
  signUp: boolean;
  redirectUrl: string | null;
  inviteToken: string;
}

const JoinTeamHeader = ({ teamName }: { teamName: string | null }) => {
  const t = useTranslations('auth');
  return (
    <Box my={4} width="120%" alignItems="center">
      <Heading
        as="h1"
        fontSize={[6, null, null, 7]}
        fontFamily="heading"
        lineHeight="heading"
        mb={[2, null, 3]}
        textAlign="center"
      >
        {t('join_a_team', { teamName })}
      </Heading>
    </Box>
  );
};

const SignUpFooter = ({ onClick }: { onClick: (arg0: any) => void }) => {
  const t = useTranslations('auth');
  const [continueUrl] = useClientSearchParam('continue');
  return (
    <>
      <Box flexDirection="row" mt={4}>
        <Text fontWeight="semiBold">{t('already_have_account')}</Text>
        <Button variant="subtle" onClick={onClick} ml={1}>
          {t('log_in')}
        </Button>
      </Box>
      <Button
        variant="subtle"
        href={`/auth/saml${continueUrl ? `?continue=${continueUrl}` : ''}`}
        my={2}
        size="compact"
      >
        {t('signup_sso')}
      </Button>
    </>
  );
};

const LogInFooter = ({ onClick }: { onClick: (arg0: any) => void }) => {
  const t = useTranslations('auth');
  return (
    <Box flexDirection="row" mt={4} alignItems="baseline">
      <Text fontWeight="semiBold">{t('new_to_menti')}</Text>
      <Button variant="subtle" onClick={onClick} ml={1}>
        {t('signup_now')}
      </Button>
    </Box>
  );
};

export default function ConfirmAccount({
  signUp,
  teamName,
  email,
  redirectUrl,
  error,
  inviteToken,
}: ConfirmAccountPropsT) {
  const { user } = useUser();
  const searchParams = useSearchParams();
  const referral = useReferral();
  const joinMode = searchParams.get('joinMode');
  const authState = searchParams.get('authState');
  const pathname = usePathname();
  const authPackage = getAuthPackage();

  function getAuthPackage() {
    const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID;
    const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
    const RUNTIME_MICROSOFT_CLIENT_ID =
      process.env.NEXT_PUBLIC_RUNTIME_MICROSOFT_CLIENT_ID;

    const fbId = FACEBOOK_CLIENT_ID || '';
    const googleId = GOOGLE_CLIENT_ID || '';
    const microsoftId = RUNTIME_MICROSOFT_CLIENT_ID || '';

    const helloOptions = {
      facebook: fbId,
      google: googleId,
      windows: microsoftId,
    };
    let authPackage;
    const createAuthPackage = () => {
      authPackage = new AuthPackage({
        baseUrl: process.env.NEXT_PUBLIC_EU_CORE_URL,
        helloOptions,
      });
      return authPackage;
    };

    return authPackage || createAuthPackage();
  }

  useEffect(() => {
    if (error) {
      const errorMessage = parseErrorCode({
        error,
        teamName,
      });

      trackDropOutReason({
        teamName,
        reason: errorMessage.trackingDropoutReason,
        userId: user?.id,
        joinMode,
        authState,
        path: window.location.pathname,
      });
    }
    trackViewedPage();
  }, [error, teamName, user?.id, joinMode, authState, pathname, searchParams]);

  return (
    <Box alignItems="center" py={[5, 6]}>
      {error ? (
        <JoinError error={error} context="Join modal" teamName={teamName} />
      ) : (
        <Box
          width={['100%', '400px']}
          className="authWrapper"
          alignItems="center"
          maxWidth="500px"
          px={3}
        >
          <MentimeterLogoType height={30} />
          <AuthSwitch
            referral={referral}
            authPackage={authPackage}
            showSignup={signUp}
            useSocialRedirect={false}
            preFilledEmail={email}
            continueUrl={redirectUrl ?? undefined}
            emailChangeDisabled={true}
            inviteToken={inviteToken}
            render={({
              showSignup,
              SSOMessageActive,
              toggleAuthMode,
              renderAuth,
            }) => {
              return (
                <>
                  <JoinTeamHeader teamName={teamName} />
                  {renderAuth()}
                  {showSignup && !SSOMessageActive && (
                    <SignUpFooter onClick={toggleAuthMode} />
                  )}
                  {!showSignup && <LogInFooter onClick={toggleAuthMode} />}
                </>
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
}

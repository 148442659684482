import { generateParticleValues } from './profiles';
import type { Bounds, Profile } from './types';

/**
 * Create a single particle Object.
 * @param profile - particle profile that contains type and velocity
 * @param bounds  - canvas width and height */
function createParticle(profile: Profile, bounds: Bounds) {
  return {
    ...generateParticleValues(profile),

    x: Math.random() * bounds.width,
    y: Math.random() * -bounds.height,

    reset() {
      this.x = Math.random() * bounds.width;
      this.y = Math.random() * -bounds.height;
    },
  };
}

/**
 * Generates a specific amount of particles to be rendered
 * on the canvas based on the specified particle profile.
 * @param profile - particle profile that contains type and velocity
 * @param amount  - the amount of particles to be rendered
 * @param bounds  - canvas width and height */
export function generateParticles(
  profile: Profile,
  amount: number,
  bounds: Bounds,
) {
  return Array(amount)
    .fill(null)
    .map(() => createParticle(profile, bounds));
}

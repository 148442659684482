/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import type { ReactNode } from 'react';
import { showNewMessage } from '@mentimeter/intercom';
import type { SubscriptionPlanFeaturesT } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Image } from '@mentimeter/ragnar-ui/image';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';
import { PlansName } from './utils';
import bullet from './images/dot.svg';

export const BulletWrapper = ({ children }: { children: ReactNode }) => (
  <Box flexDirection="row">
    <Image src={bullet.src} alt="checklist bullet" />
    <Text ml={2}>{children}</Text>
  </Box>
);

export const Intro = ({ email }: { email: string }) => (
  <P mt={3} mb={2}>
    If you ({email}) join this workspace:
  </P>
);

export const ContactSupport = () => (
  <Text mt={3} mb={4}>
    Questions? Contact{' '}
    <Link
      underline={false}
      onClick={(ev) => {
        ev.preventDefault();
        showNewMessage();
      }}
    >
      support
    </Link>
    .
  </Text>
);

export const JoinTeamPaidToMemberLite = ({
  email,
  currentPlan,
}: {
  email: string;
  currentPlan: SubscriptionPlanFeaturesT;
}) => {
  const currentPlanName = PlansName[currentPlan];
  return (
    <>
      <Intro email={email} />
      <BulletWrapper>
        <Strong>Your {currentPlanName} subscription will be cancelled</Strong>
      </BulletWrapper>
      <BulletWrapper>
        You&apos;ll have a <Strong>Free subscription</Strong> with limited
        features
      </BulletWrapper>
      <BulletWrapper>
        All of your existing presentations will be saved
      </BulletWrapper>
    </>
  );
};

import type { Profile } from './types';

/**
 * Retrieves a random color from the supplied color
 * colorPalette.
 * @param colorPalette - contains hex color strings
 * @return random hex color code */
function getRandomPaletteColor(colorPalette: string[]) {
  const i = Math.floor(Math.random() * colorPalette.length);

  return colorPalette[i]!;
}

const TYPES = {
  /**
   * Retrieves the visual values of a `snow` particle.
   * @param colorPalette - optional hex color strings */
  snow(colorPalette?: string[]) {
    const color =
      colorPalette && colorPalette.length
        ? getRandomPaletteColor(colorPalette)
        : '#ffffff';

    return {
      color,
      radius: 0.4 + Math.random() * 2,
      opacity: 0.5 + Math.random() * 0.5,
      deltaOpacity: 0,
    };
  },

  /**
   * Retrieves the visual values of a `confetti` particle.
   * @param colorPalette - optional hex color strings */
  confetti(colorPalette?: string[]) {
    const color = colorPalette?.length
      ? getRandomPaletteColor(colorPalette)
      : '#ffffff';

    return {
      color,
      radius: 0.2 + Math.random() * 4,
      opacity: 1,
      deltaOpacity: 0.05 * Math.random(),
    };
  },
};

const VELOCITIES = {
  /** Retrieves the kinetic values of a `slow` particle. */
  slow() {
    return {
      deltaX: 0.35 - Math.random(),
      deltaY: 0.15 + Math.random() * 1.1,
    };
  },

  /** Retrieves the kinetic values of a `steady` particle. */
  steady() {
    return {
      deltaX: 0.25 - Math.random(),
      deltaY: 0.8 + Math.random() * 0.4 + Math.random() * 2,
    };
  },

  /** Retrieves the kinetic values of a `fast` particle. */
  fast() {
    return {
      deltaX: 0.25 - Math.random(),
      deltaY: 1.1 + Math.random() * 0.4 + Math.random() * 2,
    };
  },
};

/** Retrieves all particle values denoted by a specific profile. */
export function generateParticleValues({
  type,
  velocity,
  colorPalette,
}: Profile) {
  return {
    ...VELOCITIES[velocity](),
    ...TYPES[type](colorPalette),
  };
}

import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';

export const ConfirmWithoutConsent = ({
  onDismiss,
  isLoading,
  onJoinWorkspaceClick,
}: {
  onDismiss: () => void;
  isLoading: boolean;
  onJoinWorkspaceClick: () => void;
}) => {
  return (
    <Box
      flexDirection={['column', 'row']}
      width="100%"
      justifyContent="flex-end"
      mt={4}
    >
      <Button
        variant="secondary"
        mr={1}
        onClick={onDismiss}
        width={['100%', 'auto', 'auto']}
      >
        No thanks
      </Button>
      <Button
        variant="primary"
        disabled={isLoading}
        onClick={onJoinWorkspaceClick}
        width={['100%', 'auto', 'auto']}
        mt={[2, 0]}
      >
        Join workspace
      </Button>
    </Box>
  );
};

import { ContactSupport } from '../_utils';
import { JoinTeamConsentFreeUserContent } from '../JoinTeamConsentFreeUserContent';
import { ConfirmWithoutConsent } from './ConfirmWithoutConsent';

export const FreeUser = ({
  onDismiss,
  isLoading,
  onJoinWorkspaceClick,
}: {
  onDismiss: () => void;
  isLoading: boolean;
  onJoinWorkspaceClick: () => void;
}) => {
  return (
    <>
      <JoinTeamConsentFreeUserContent />
      <ContactSupport />
      <ConfirmWithoutConsent
        onDismiss={onDismiss}
        isLoading={isLoading}
        onJoinWorkspaceClick={onJoinWorkspaceClick}
      />
    </>
  );
};

import { core } from '@mentimeter/http-clients';
import useSWRMutation from 'swr/mutation';
import { mutate } from 'swr';
import { mutateWorkspace } from '@mentimeter/workspace-data-hooks';
import type { JoinErrorEnum } from '../join-page/handleErrorMessage';

const CONSUME_TOKEN_CACHE_KEY = '/consume-token';

interface UseConsumeOptions {
  onSuccess: () => void;
  onError: (error: JoinErrorEnum) => void;
}

// This needs to be redeclared because the trigger interface is not exported from SWR
type ConsumeToken = () => void;

export function useConsumeToken(
  token: string,
  { onError, onSuccess }: UseConsumeOptions,
) {
  const {
    trigger: consumeToken,
    error,
    isMutating,
  } = useSWRMutation(
    CONSUME_TOKEN_CACHE_KEY,
    async () => core().workspaces.pendingMemberships.consume(token),
    {
      onSuccess: () => {
        mutate('/users');
        mutateWorkspace();
        onSuccess();
      },
      onError(error) {
        onError(getErrorCode(error));
      },
    },
  );

  if (isMutating) {
    return {
      consumeToken: consumeToken as ConsumeToken,
      isLoading: true,
      error: null,
    };
  }

  if (error) {
    return {
      consumeToken: consumeToken as ConsumeToken,
      isLoading: false,
      error: getErrorCode(error),
    };
  }

  return {
    consumeToken: consumeToken as ConsumeToken,
    isLoading: false,
    error: null,
  };
}

export function getErrorCode(error: any): JoinErrorEnum {
  return error.response?.data?.code ?? 'error';
}

'use client';

import React from 'react';
import { useUser } from '@mentimeter/user';
import { trackViewedPage } from '@mentimeter/dashboard-tracking';
import {
  usePathname,
  useRouter,
  useSearchParams,
} from '@mentimeter/next-navigation';
import { ClaimInviteTokenSuccess } from './ClaimInviteTokenSuccess';
import { JoinError } from './JoinError';
import type { JoinErrorEnum } from './handleErrorMessage';
import { parseErrorCode } from './handleErrorMessage';
import { trackDropOutReason } from './utils';
import { TermsOfUse } from './TermsOfUse';
import { ClaimInviteLayout } from './ClaimInviteLayout';
import { ClaimInviteForm } from './ClaimInviteForm';
export interface JoinWorkspaceT {
  id: number | null;
  teamUrl: string;
  teamName: string;
  emailDomains?: string | undefined | null;
  samlLoginUrl?: string | undefined;
  joinLinkRestrictionEnabled: boolean | null;
  requestToJoinEnabled: boolean | null;
}

export interface ClaimInvite {
  error: JoinErrorEnum | null;
  claims: number;
  email: string;
  url: string;
  status: number | null;
}

export interface ClaimInviteTokenPropsT {
  publicWorkspace: JoinWorkspaceT;
  publicWorkspaceError?: JoinErrorEnum | null;
}

export default function ClaimInviteToken({
  publicWorkspace,
  publicWorkspaceError,
}: ClaimInviteTokenPropsT) {
  const { user, isLoading } = useUser();
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const { teamUrl, teamName, emailDomains, joinLinkRestrictionEnabled } =
    publicWorkspace;
  const [claimInvite, setClaimInvite] = React.useState<ClaimInvite>({
    error: publicWorkspaceError ?? null,
    claims: 0,
    email: '',
    url: teamUrl,
    status: null,
  });
  const joinMode = searchParams ? searchParams.get('joinMode') : null;
  const authState = searchParams ? searchParams.get('authState') : null;

  React.useEffect(() => {
    if (claimInvite.error) {
      const errorMessage = parseErrorCode({
        error: claimInvite.error,
        teamName,
      });

      trackDropOutReason({
        teamName,
        reason: errorMessage.trackingDropoutReason,
        userId: user?.id,
        joinMode,
        authState,
        path: window.location.pathname,
        isSamlWorkspace: Boolean(publicWorkspace.samlLoginUrl),
      });
    }
    trackViewedPage();
  }, [
    claimInvite.error,
    teamName,
    user?.id,
    joinMode,
    authState,
    pathname,
    searchParams,
    publicWorkspace.samlLoginUrl,
  ]);

  /**
   * We need to wait for the user response to come back, otherwise tests that
   * are not dependant on the user will be flakey because their expect will
   * pass too early and the user response will come back after the test has
   * unmounted and the document is no longer available.
   */
  if (isLoading) return null;

  if (claimInvite.status === 204) {
    router.push('/app');
    return null;
  }

  if (claimInvite.error && !hasMembershipError(claimInvite.error)) {
    return (
      <JoinError
        error={claimInvite.error}
        context="Join page"
        teamName={teamName}
      />
    );
  }

  if (claimInvite.claims > 0) {
    return (
      <ClaimInviteLayout>
        <ClaimInviteTokenSuccess
          claimInvite={claimInvite}
          onClaimInviteSuccess={() => {
            setClaimInvite((state) => ({
              ...state,
              claims: state.claims + 1,
            }));
          }}
          onClaimInviteError={onClaimInviteError}
        />
      </ClaimInviteLayout>
    );
  }

  return (
    <ClaimInviteLayout>
      <ClaimInviteForm
        emailDomains={emailDomains}
        teamUrl={teamUrl}
        teamName={teamName}
        joinLinkRestrictionEnabled={joinLinkRestrictionEnabled}
        setClaimInvite={setClaimInvite}
        onClaimInviteError={onClaimInviteError}
      />
      <TermsOfUse />
    </ClaimInviteLayout>
  );

  function hasMembershipError(error: JoinErrorEnum) {
    const code = parseErrorCode({ error, teamName });

    return code.isMembershipError;
  }

  function onClaimInviteError(error: JoinErrorEnum) {
    setClaimInvite((state) => ({
      ...state,
      error,
    }));
  }
}

import { getVisitorToken } from '@mentimeter/splitio';
import { core, getWorkspaceRegion, tracking } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';

interface TrackDropOutReasonT {
  teamName: string | null;
  reason: string;
  userId?: number | undefined;
  joinMode?: string | null;
  authState?: string | null;
  path: string;
  isSamlWorkspace?: boolean | undefined;
}

export const trackDropOutReason = (props: TrackDropOutReasonT) => {
  const visitorToken = getVisitorToken();
  const event = props.isSamlWorkspace
    ? 'User failed to complete sso join flow'
    : 'User failed to complete join flow';
  const payload = {
    event,
    properties: {
      'workspace name': props.teamName,
      reason: props.reason,
      'join source': props.joinMode,
      'auth state': props.authState,
      path: props.path,
    },
  };

  if (props.userId) {
    trackUser(payload);
  } else if (!props.userId && visitorToken) {
    tracking().trackVisitor(payload, visitorToken);
  }
};

export const handleSubmit = async ({
  email,
  url,
}: { email: string; url: string }) => {
  const region = await getWorkspaceRegion(url);
  const response = await core({ region }).workspaces.pendingMemberships.claim({
    url,
    email,
  });
  return response;
};

const DEFAULT_PATH = '/app/onboarding';

export const useOnboardingPageRouting = (): {
  onboardingPath: typeof DEFAULT_PATH;
} => {
  const onboardingPath = DEFAULT_PATH;

  return {
    onboardingPath,
  };
};

import { WorkspaceRoleEnum, core } from '@mentimeter/http-clients';
import useSWR from 'swr';
import { JoinErrorEnum } from '../join-page/handleErrorMessage';
import { getErrorCode } from './use-consume-token';
import type { TeamDetailsT } from './utils';

const DEFAULT_WORKSPACE_NAME = 'Workspace';
const DEFAULT_PLAN_FEATURES = 'registered';

const TEAM_DETAILS_CACHE_KEY = '/team-details';

interface UseTeamDetailsOptions {
  onError: (error: JoinErrorEnum) => void;
}

export function useTeamDetails(
  token: string | null,
  { onError }: UseTeamDetailsOptions,
) {
  const fetcher = async () => {
    if (token) {
      return (await core().workspaces.pendingMemberships.get(token)).data;
    }
    return null;
  };

  const { data, error, isLoading } = useSWR(
    token ? TEAM_DETAILS_CACHE_KEY : null,
    fetcher,
    {
      revalidateOnFocus: false,
      onError: (error) => {
        onError(getErrorCode(error));
      },
      onErrorRetry: (error) => {
        const code = getErrorCode(error);
        return code !== JoinErrorEnum.WORKSPACE_INVITE_NOT_FOUND;
      },
    },
  );

  if (isLoading) {
    return {
      isFetchingTeamDetails: true,
      error: null,
      teamDetails: getDefaultTeamState(),
    };
  }

  if (error || !data) {
    return {
      isFetchingTeamDetails: false,
      error: JoinErrorEnum.WORKSPACE_INVITE_NOT_FOUND,
      teamDetails: getDefaultTeamState(),
    };
  }

  return {
    isFetchingTeamDetails: false,
    error: null,
    teamDetails: {
      name: data.workspace.name || DEFAULT_WORKSPACE_NAME,
      planFeatures:
        data.workspace.active_subscription?.plan.features ||
        DEFAULT_PLAN_FEATURES,
      members_count: data.workspace.members_count,
      invited_role: data.invited_role,
    },
  };
}

function getDefaultTeamState(): TeamDetailsT {
  return {
    name: DEFAULT_WORKSPACE_NAME,
    planFeatures: DEFAULT_PLAN_FEATURES,
    members_count: 0,
    invited_role: WorkspaceRoleEnum.USER,
  };
}

import React from 'react';
import { type SubscriptionPlanFeaturesT } from '@mentimeter/http-clients';
import { Strong } from '@mentimeter/ragnar-ui/typography';
import { BulletWrapper, Intro } from './_utils';
import { PlansName } from './utils';

export const JoinTeamConsentPaidUserContent = ({
  email,
  currentPlan,
  newPlan,
}: {
  email: string;
  currentPlan: SubscriptionPlanFeaturesT;
  newPlan: SubscriptionPlanFeaturesT;
}) => {
  const currentPlanName = PlansName[currentPlan];

  return (
    <>
      <Intro email={email} />
      <BulletWrapper>
        join a <Strong>{`${PlansName[newPlan]}`} subscription</Strong> and enjoy
        all the benefits
      </BulletWrapper>
      <BulletWrapper>
        <Strong>cancel</Strong> your current {currentPlanName} subscription
      </BulletWrapper>
      <BulletWrapper>
        keep all your <Strong>presentations</Strong>
      </BulletWrapper>
    </>
  );
};

import React from 'react';
import { Message } from '@mentimeter/ragnar-form';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { parseToClientMessage } from '../../utils/parseToClientMessage';
import {
  ErrorCodes,
  type ClientAuthError,
  type ServerAuthError,
} from '../../clients/userApi/errors';
import { ExposedCredentialsLoginError } from './ExposedCredentialsLoginError';

interface Props extends BoxT {
  error: ClientAuthError | ServerAuthError;
  continueUrl: string;
}

export const AuthErrorMessage = ({ error, continueUrl, ...props }: Props) => {
  if (error.code === ErrorCodes.EXPOSED_CREDENTIALS && error.type === 'login') {
    return <ExposedCredentialsLoginError />;
  }

  const clientMessage = parseToClientMessage(error, continueUrl);
  if (!clientMessage) return null;

  return (
    <Message severity={clientMessage.severity} {...props}>
      {clientMessage.children}
    </Message>
  );
};

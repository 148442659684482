import React from 'react';
import { showNewMessage } from '@mentimeter/intercom';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Image } from '@mentimeter/ragnar-ui/image';
import { P, Heading } from '@mentimeter/ragnar-ui/typography';
import magnifying from './images/magnifying.svg';
import moon from './images/moon.svg';
import handshake from './images/handshake.svg';
import letter from './images/letter.svg';
import puzzle from './images/puzzle.svg';
import cat from './images/sleeping-cat.svg';
import type { JoinErrorEnum } from './handleErrorMessage';
import { ErrorImage, parseErrorCode } from './handleErrorMessage';

const errorImage = (image: ErrorImage) => {
  if (image === ErrorImage.MOON) {
    return moon.src;
  }
  if (image === ErrorImage.HANDSHAKE) {
    return handshake.src;
  }
  if (image === ErrorImage.LETTER) {
    return letter.src;
  }
  if (image === ErrorImage.PUZZLE) {
    return puzzle.src;
  }
  if (image === ErrorImage.CAT) {
    return cat.src;
  }
  return magnifying.src;
};

const handleClick = (dropoutReason: string, context: string) => {
  showNewMessage();
  void trackUser({
    event: 'Clicked contact support',
    properties: {
      context: `${context}`,
      error: `${dropoutReason}`,
    },
  });
};

const ContactButtons = ({
  trackingDropoutReason,
  context,
}: {
  trackingDropoutReason: string;
  context: string;
}) => (
  <Box flexDirection="row" mt={3}>
    <Button
      data-testid="join-team-contact-support-button"
      target="_blank"
      variant="primary"
      onClick={() => handleClick(trackingDropoutReason, context)}
    >
      Contact support
    </Button>
  </Box>
);

export function JoinError({
  error,
  teamName,
  context,
  headingFontSize = 7,
  pFontSize = 4,
}: {
  error: JoinErrorEnum;
  teamName: string;
  context: string;
  headingFontSize?: number;
  pFontSize?: number;
}) {
  const joinError = parseErrorCode({ error, teamName });

  if (!joinError) {
    return null;
  }

  return (
    <Box
      alignItems="center"
      flex="1 1 auto"
      justifyContent={[null, 'center', 'center']}
      height="100%"
    >
      {joinError.image && (
        <Image
          src={errorImage(joinError.image)}
          alt="illustration"
          height="280px"
          width="280px"
          mt={[3, null, null, null]}
        />
      )}
      <Heading
        textAlign="center"
        mt={4}
        mb={3}
        fontSize={headingFontSize}
        fontWeight="bold"
      >
        {joinError.title}
      </Heading>
      <P textAlign="center" fontSize={pFontSize}>
        {joinError.main}
      </P>
      {joinError.ctaButton?.support && (
        <ContactButtons
          trackingDropoutReason={joinError.trackingDropoutReason}
          context={context}
        />
      )}
      {joinError.ctaButton?.manageMembers && (
        <Box flexDirection="row" mt={3}>
          <Button target="_blank" variant="primary" href="/app/manage-members">
            Manage members
          </Button>
        </Box>
      )}
    </Box>
  );
}

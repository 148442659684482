import React, { useState } from 'react';
import { CheckIcon } from '@mentimeter/ragnar-visuals';
import { getVisitorToken } from '@mentimeter/splitio';
import { core, getWorkspaceRegion, tracking } from '@mentimeter/http-clients';
import type { UserT } from '@mentimeter/user';
import { useUser } from '@mentimeter/user';
import { showNewMessage } from '@mentimeter/intercom';
import { trackUser } from '@api/tracking/client';
import { Image } from '@mentimeter/ragnar-ui/image';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, P, Meta, Strong } from '@mentimeter/ragnar-ui/typography';
import type { JoinErrorEnum } from './handleErrorMessage';
import type { ClaimInvite } from './ClaimInviteToken';
import success from './images/claim-invite-token-success.png';

const handleSubmit = async (email: string, url: string) => {
  const region = await getWorkspaceRegion(url);
  await core({ region }).workspaces.pendingMemberships.claim({
    url,
    email,
  });
};

const trackResendEmail = (url: string, user: UserT | null) => {
  const visitorToken = getVisitorToken();
  const payload = {
    event: 'Clicked resend email',
    properties: {
      context: 'join flow',
      'workspace url': url,
    },
  };

  if (user) {
    trackUser(payload);
  } else if (visitorToken) {
    tracking().trackVisitor(payload, visitorToken);
  }
};

export function ClaimInviteTokenSuccess({
  claimInvite,
  onClaimInviteSuccess,
  onClaimInviteError,
}: {
  claimInvite: ClaimInvite;
  onClaimInviteSuccess: () => void;
  onClaimInviteError: (error: JoinErrorEnum) => void;
}) {
  const { user } = useUser();
  const [disableVerify, setDisableVerify] = useState(false);
  const { email, url, claims } = claimInvite;
  const hasResentVerification = claims > 1;

  return (
    <>
      <Box height={[null, '265px']}>
        <Image
          src={success.src}
          alt="Your verification email was successfully sent"
        />
      </Box>
      <Heading textAlign="center" mt={4} mb={3} fontSize={6}>
        Verify your email
      </Heading>
      <P mb={3} textAlign="center">
        We sent an email to <Strong>{email}</Strong> with a link to verify your
        account. If you have not received any email within a few minutes, check
        your spam folder or try again.
      </P>
      {hasResentVerification ? (
        <Button
          aria-label="Email resent"
          variant="positive"
          disabled={disableVerify}
          onClick={() => setDisableVerify(true)}
          iconLeading={CheckIcon}
        >
          Email resent
        </Button>
      ) : (
        <Button
          aria-label="Resend email"
          variant="tertiary"
          onClick={async () => {
            trackResendEmail(url, user);
            try {
              await handleSubmit(email, url);
              onClaimInviteSuccess();
            } catch (e: any) {
              const error = e?.response?.data?.code || 'default';
              onClaimInviteError(error);
            }
          }}
        >
          Resend email
        </Button>
      )}
      {disableVerify && (
        <Meta mt={3} textAlign="center">
          There seems to be an issue verifying your email. Please contact{' '}
          <Link
            underline={false}
            onClick={(ev) => {
              ev.preventDefault();
              showNewMessage();
            }}
          >
            support
          </Link>{' '}
          to get more help.
        </Meta>
      )}
    </>
  );
}

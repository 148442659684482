import React, { useState, useMemo } from 'react';
import { type UserT } from '@mentimeter/user';
import type {
  SubscriptionsResponseT,
  TrackingContext,
  WorkspaceRole,
} from '@mentimeter/http-clients';
import { usePathname, useRouter } from '@mentimeter/next-navigation';
import { trackUser } from '@api/tracking/client';
import { isSafeUrl } from '@mentimeter/auth';
import {
  ModalContainer,
  ModalRoot,
  ModalBody,
  ModalHeader,
} from '@mentimeter/ragnar-ui/modal';
import { ScreenReaderOnly } from '@mentimeter/ragnar-ui/screenreader-only';
import {
  type JoinErrorEnum,
  parseErrorCode,
} from '../join-page/handleErrorMessage';
import { trackDropOutReason } from '../join-page/utils';
import { JoinTeamModalContent } from './JoinTeamModalContent';
import {
  isAllowedToJoin,
  type AuthStateT,
  type JoinModeT,
  type TeamDetailsT,
} from './utils';
import { useConsumeToken } from './use-consume-token';
import type { ConsumeStatus } from './types';

export const JoinTeamModalContainer = ({
  token,
  authState,
  joinMode,
  inviteSeriesId,
  user,
  subscriptions,
  trackingContext,
  continuePath,
  teamDetails,
  teamDetailsError,
}: {
  token: string;
  authState: AuthStateT;
  joinMode: JoinModeT;
  inviteSeriesId: string | undefined;
  user: UserT;
  subscriptions: SubscriptionsResponseT | undefined;
  trackingContext: TrackingContext;
  continuePath?: string;
  teamDetails: TeamDetailsT;
  teamDetailsError?: JoinErrorEnum | null;
}) => {
  const [hasConsumedToken, setHasConsumedToken] = useState(false);
  const allowedToJoin = useMemo(
    () => isAllowedToJoin(user, subscriptions),
    [user, subscriptions],
  );
  const router = useRouter();
  const pathname = usePathname();
  const [open, setOpen] = useState(true);

  const {
    isLoading: isConsumingToken,
    consumeToken,
    error: consumeTokenError,
  } = useConsumeToken(token, {
    onError: (code) => {
      const errorMessage = parseErrorCode({
        teamName: teamDetails.name,
        error: code,
      });
      trackDropOutReason({
        reason: errorMessage.trackingDropoutReason,
        userId: user.id,
        teamName: teamDetails.name,
        joinMode,
        authState,
        path: window.location.pathname,
      });
    },
    onSuccess: () => {
      setHasConsumedToken(true);

      trackConfirmationAccepted({
        plan: teamDetails.planFeatures,
        role: teamDetails.invited_role,
        activeTeamMembers: teamDetails.members_count || 0,
        context: trackingContext,
      });
    },
  });

  const consumeStatus: ConsumeStatus = useMemo(() => {
    if (hasConsumedToken) return { state: 'success' };
    if (consumeTokenError) return { error: consumeTokenError, state: 'error' };
    if (teamDetailsError) return { error: teamDetailsError, state: 'error' };
    if (!allowedToJoin.valid)
      return { error: allowedToJoin.code, state: 'error' };

    return { state: 'pending-consent' };
  }, [allowedToJoin, consumeTokenError, hasConsumedToken, teamDetailsError]);

  const successActionText = inviteSeriesId
    ? 'Start collaborating'
    : 'Start exploring';

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer width={['100%', '100%', '800px']}>
        <ScreenReaderOnly>
          <ModalHeader />
        </ScreenReaderOnly>
        <ModalBody p={0}>
          <JoinTeamModalContent
            isLoading={isConsumingToken}
            consumeStatus={consumeStatus}
            user={user}
            teamDetails={teamDetails}
            teamName={teamDetails.name}
            successActionText={successActionText}
            onDismiss={onDismiss}
            onJoinWorkspaceClick={consumeToken}
            onSuccessActionClick={completeJoin}
          />
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );

  function completeJoin() {
    setOpen(false);

    if (continuePath && isSafeUrl(continuePath)) {
      router.replace(continuePath);
    } else {
      router.replace(pathname); //reset query params
    }
  }

  function onDismiss() {
    setOpen(false);

    trackDropOutReason({
      reason: 'clicked cancel',
      teamName: teamDetails.name,
      userId: user.id,
      joinMode,
      authState,
      path: window.location.pathname,
    });
  }
};

function trackConfirmationAccepted({
  context,
  plan,
  role,
  activeTeamMembers,
}: {
  context: TrackingContext;
  plan: string;
  role: WorkspaceRole;
  activeTeamMembers: number;
}) {
  trackUser({
    event: 'Joined workspace through invite',
    properties: {
      context,
      'active team members': activeTeamMembers,
      'current plan': plan,
      'role in workspace': role,
    },
  });
}

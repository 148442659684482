import { LinkClickable } from '@mentimeter/next-navigation';
import { sand100 } from '@mentimeter/ragnar-colors';
import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { Device } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Image } from '@mentimeter/ragnar-ui/image';
import imageComputer from './images/image-computer-and-avatars.png';

export const ClaimInviteLayout = ({
  children,
}: { children: React.ReactNode }) => {
  return (
    <Box flexDirection="row" width="100%">
      <Device.Match greaterThan={2}>
        <Box
          bg={sand100}
          width="40%"
          minWidth="430px"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <LinkClickable
            href="/"
            width="34px"
            height="34px"
            position="absolute"
            extend={({ theme }) => ({
              top: `${theme.kosmosSpacing.space6}px`,
              left: `${theme.kosmosSpacing.space8}px`,
            })}
          >
            <MentimeterSymbol variant="mono" />
          </LinkClickable>
          <Image
            src={imageComputer.src}
            alt="a person with a computer"
            width={400}
            height={308}
          />
        </Box>
      </Device.Match>
      <Box
        width={['100%', '100%', '60%']}
        p="space10"
        maxWidth="100%"
        minHeight="100vh"
        flexDirection="column"
        justifyContent={['flex-start', null, 'center']}
        alignItems={['center', 'center']}
      >
        {children}
      </Box>
    </Box>
  );
};

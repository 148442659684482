import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { JoinErrorEnum } from '../join-page/handleErrorMessage';
import { JoinError } from '../join-page/JoinError';

interface JoinTeamErrorModalProps {
  error: JoinErrorEnum;
  teamName: string;
}

export const JoinTeamErrorModalContent = ({
  error,
  teamName,
}: JoinTeamErrorModalProps) => {
  return (
    <Box alignItems="center" maxWidth="800px" width="100%" mx="auto" p={3}>
      <JoinError
        error={error}
        context="Join modal"
        headingFontSize={4}
        pFontSize={2}
        teamName={teamName}
      />
    </Box>
  );
};

import React from 'react';
import { P, Strong } from '@mentimeter/ragnar-ui/typography';

export const JoinTeamConsentFreeUserContent = () => {
  return (
    <P mt={3} mb={2}>
      You can collaborate on presentations, surveys, and more when you join a
      workspace. Everything you&apos;ve already created can still be found in{' '}
      <Strong>My presentations</Strong>.
    </P>
  );
};

import { trackUser } from '@api/tracking/client';
import {
  type SubscriptionPlanFeaturesT,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { useOnce } from '@mentimeter/react-hooks';
import type { UserT } from '@mentimeter/user';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import et from '../images/et-1.svg';
import hand from '../images/hands-1.svg';
import { ModalContainer } from '../ModalContainer';
import { FreeUser } from './FreeUser';
import { PaidUser } from './PaidUser';

const trackConfirmationShown = (userPlan: string) => {
  trackUser({
    event: 'Join flow confirmation modal shown',
    properties: {
      context: 'join flow confirmation modal shown',
      'current plan type': `${userPlan}`,
    },
  });
};

export function JoinTeamConsent({
  onJoinWorkspaceClick,
  user,
  isLoading,
  onDismiss,
  newPlan,
  teamName,
  invitedRole,
}: {
  onJoinWorkspaceClick: () => void;
  user: Pick<UserT, 'isFreeUser' | 'email'> & {
    features: { type: UserT['features']['type'] };
  };
  isLoading: boolean;
  onDismiss: () => void;
  newPlan: SubscriptionPlanFeaturesT;
  teamName: string;
  invitedRole: WorkspaceRole;
}) {
  useOnce(!!user.features.type, () => {
    trackConfirmationShown(user.features.type);
  });

  const content = user.isFreeUser ? (
    <FreeUser
      onDismiss={onDismiss}
      isLoading={isLoading}
      onJoinWorkspaceClick={onJoinWorkspaceClick}
    />
  ) : (
    <PaidUser
      invitedRole={invitedRole}
      user={user}
      newPlan={newPlan}
      isLoading={isLoading}
      onDismiss={onDismiss}
      onJoinWorkspaceClick={onJoinWorkspaceClick}
    />
  );

  return (
    <ModalContainer image={(user.isFreeUser ? hand.src : et.src) as string}>
      <Heading mb={2}>
        Please note before joining {teamName}&apos;s workspace!
      </Heading>
      {content}
    </ModalContainer>
  );
}

import * as React from 'react';
import { useTranslations, Trans } from '@mentimeter/i18n';
import { Meta } from '@mentimeter/ragnar-ui/typography';
import { Link } from '@mentimeter/ragnar-ui/link';

export const Terms = () => {
  const t = useTranslations('authPackage');
  return (
    <Meta as="p" textAlign="center">
      <Trans
        t={t}
        i18nKey="form_disclaimer"
        components={{
          TermsLink: (
            <Link
              color="inherit"
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
          PoliciesLink: (
            <Link
              color="inherit"
              href="/policies"
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
        }}
      />
    </Meta>
  );
};

export default Terms;
